<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-alert
          v-if="!Number.isInteger(form.number)"
          dense
          type="error"
        >
            Указано некорректное значение поля "Урок"
        </v-alert>

        <v-switch
            v-model="form.number"
            :color="$const.color.primary"
            :true-value="1"
            :false-value="-99"
            :value-comparator="(value) => value > -99"
            label="Отображать запись"
            @change="updateLessonType"
        ></v-switch>

        <v-text-field 
            v-model="form.year"
            :error-messages="getErrors('form.year')"
            label="Год"
            name="year"
            type="text" 
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
            v-model="form.subject"
            :items="subjects"
            :error-messages="getErrors('form.subject')"
            :disabled="loading.fetchThemes"
            label="Предмет"
            name="subject"
            :color="$const.color.primary"
            @change="fetchThemes"
        />

        <v-select
            v-model="form.grade"
            :items="grades"
            :disabled="!form.subject || loading.fetchThemes"
            :error-messages="getErrors('form.grade')"
            label="Класс"
            name="grade"
            :color="$const.color.primary"
            class="mb-2"
            @change="fetchThemes"
        />

        <v-select
            :error-messages="getErrors('form.number')"
            v-model="lessonType"
            :items="availableTypes"
            dense
            :color="$const.color.primary" 
            label="Тип урока"
            class="mb-4"
        />

        <v-text-field
            v-if="form.number > 0"
            v-model="form.number"
            :error-messages="getErrors('form.number')"
            label="Номер урока"
            type="number" 
            :color="$const.color.primary" 
            @input="onFormNumberUpdated"
        ></v-text-field>

        <v-text-field 
            v-model="form.theme"
            :error-messages="getErrors('form.theme')"
            label="Тема"
            name="theme"
            type="text" 
            :color="$const.color.primary"
        ></v-text-field>

        <v-autocomplete
            v-model="form.themes"
            label="Темы ФИОКО"
            :items="themes"
            :disabled="themes.length === 0"
            :color="$const.color.primary" 
            multiple
            small-chips
            deletable-chips
        ></v-autocomplete>

        <v-label>Содержание</v-label>
        <wysiwyg-editor
            :value="form.content"
            show-source
            class="mb-5"
            @input="onWysiwygInput"
        />
        <v-alert
          v-if="getErrors('form.content').length"
          dense
          type="error"
        >
            <p v-for="(message, index) in getErrors('form.content')" :key="index" class="mb-1">
                {{ message }}
            </p>
        </v-alert>

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        >
            <v-btn
                v-if="model && model.id"
                dark
                outlined
                :color="$const.color.primary"
                small
                target="_blank"
                :href="`/lesson-plan/preview/${model.id}`"
            >
                <v-icon dark class="mr-2">mdi-eye</v-icon>
                Превью
            </v-btn>
        </action-buttons>
    </v-form>
</template>
<script>

import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'

export default {
    components: { WysiwygEditor, ActionButtons },
    props: {
        model: { type: Object }
    },
    provide () {
        return {
            model: this.model
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: {
                initial: false,
                fetchThemes: false
            },
            storeModule: 'lesson_plan',
            lessonType: 'lesson',
            themes: [],
            form: {
                year: null,
                subject: '',
                grade: null,
                number: 1,
                theme: '',
                content: ''
            }
        };
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        },
        availableTypes () {
            return [
                { text: '[Скрыто от отображения]', value: 'hidden', number: -99 },
                { text: 'Тематическое и поурочное планирование', value: 'planning', number: -1 },
                { text: 'Вводный материал', value: 'initial', number: 0 },
                { text: 'Урок', value: 'lesson', number: 1 }
            ]
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
            await this.fetchThemes()
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }

        if (this.model.themes?.[0]?.id) {

            this.form.themes = this.model.themes.map((theme) => ({ value: theme.id, text: theme.name }))

        }

        this.updateLessonType()
    },
    validations () {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                number: {
                    required,
                    minValue: minValue(-99)
                },
                theme: { required: (value) => this.form.number > 0 ? required(value) : true },
                content: { required }
            }
        }
    },
    methods: {
        async fetchThemes () {

            if (this.loading.fetchThemes) return

            this.themes = []
            this.form.themes = []

            const taskFetchOptions = {
                fields: 'themes',
                filter: { subject: this.form.subject, grade: this.form.grade }
            }

            try {

                this.loading.fetchThemes = true

                const { success, data, error } = await this.$store.dispatch('task/list', taskFetchOptions)
                
                if (!success) throw new Error(error)

                for (const item of data.items) {
                    
                    for (const theme of item.themes) {
                        
                        if (this.themes.find((t) => t.value === theme.id)) continue

                        this.themes.push({ value: theme.id, text: theme.name })
                    }
                }

            } catch (e) {

                this.themes = []
                console.error(e)

            } finally {

                this.loading.fetchThemes = false
            }

        },
        onFormNumberUpdated () {
            this.form.number = parseInt(this.form.number)
            this.updateLessonType()
        },
        updateLessonType () {
            
            if (this.form.number > 0) {
            
                this.lessonType = 'lesson'

            } else if (this.form.number === 0) {

                this.lessonType = 'initial'

            } else if (this.form.number === -1) {

                this.lessonType = 'planning'

            } else if (this.form.number === -99) {

                this.lessonType = 'hidden'

            } else {

                this.lessonType = null

            }
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        },
        onWysiwygInput (html) {
            if (typeof html === 'string') {
                this.form.content = html
            }
        }
    },
    watch: {
        lessonType: {
            handler () {
                const availableType = this.availableTypes.find(v => v.value === this.lessonType)

                if (!availableType) return

                this.form.number = availableType.number
            },
            immediate: true
        }
    }
}
</script>